import React from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './mainPageTitle.module.css'

const MainPageTitle = ({
    title = 'Hello',
    text = 'How are you?',
    parentClasses,
}) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `hOne ${titleClass}`

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <h1 className={titleClassCombined}>
                    {title}
                    <span className={classes.stop}>.</span>
                </h1>
                <h2 className={classes.text}>{text}</h2>
                <div className={classes.divider} />
            </div>
        </div>
    )
}

export default MainPageTitle
