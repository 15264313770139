import { graphql } from 'gatsby'
import React from 'react'
import { Seo } from '../components/seo'
import { getImage } from 'gatsby-plugin-image'
import { Spacer } from '../components/spacer'
import Button from '../components/button'
import Cta from '../components/cta'
import SideBySide from '../components/sideBySide'
import MainPageTitle from '../components/mainPageTitle'
//import ContentBlock from '../components/contentBlock'

const IndexPage = ({ data: { markdownRemark } }) => {
    const { frontmatter } = markdownRemark

    return (
        <>
            <MainPageTitle
                title={frontmatter?.Hero?.HeroTitle}
                text={frontmatter?.Hero?.HeroText}
            />
            <Spacer />
            {/* <ContentBlock
                text={frontmatter?.CB_Text}
                parentClasses={{
                    container: 'w-full text-center',
                }}
            />
            <Spacer /> */}
            <Cta
                text={frontmatter?.CTA1_Text}
                parentClasses={{
                    bgColor: 'bg-secondary',
                }}
            >
                <Button
                    url="/contact"
                    text={frontmatter?.CTA1_ButtonText}
                    variation="primary"
                />
            </Cta>
            <SideBySide
                image={getImage(
                    frontmatter?.Freedom?.Image?.childImageSharp
                        ?.gatsbyImageData
                )}
                title={frontmatter?.Freedom?.Title}
                text={frontmatter?.Freedom?.Text}
                imagePosition="left center"
                parentClasses={{
                    orientation: 'flex-col lg_flex-row-reverse',
                }}
            />
            <SideBySide
                image={getImage(
                    frontmatter?.HolidayTents?.Image?.childImageSharp
                        ?.gatsbyImageData
                )}
                title={frontmatter?.HolidayTents?.Title}
                text={frontmatter?.HolidayTents?.Text}
                parentClasses={{
                    bgColor: 'bg-light',
                }}
            >
                <Button
                    url="/holiday-tents"
                    text="Find out more"
                    variation="primary"
                />
            </SideBySide>
            <SideBySide
                image={getImage(
                    frontmatter?.EventTents?.Image?.childImageSharp
                        ?.gatsbyImageData
                )}
                title={frontmatter?.EventTents?.Title}
                text={frontmatter?.EventTents?.Text}
                parentClasses={{
                    orientation: 'flex-col lg_flex-row-reverse',
                }}
            >
                <Button
                    url="/event-tents"
                    text="Find out more"
                    variation="primary"
                />
            </SideBySide>
            <SideBySide
                image={getImage(
                    frontmatter?.Sustainability?.Image?.childImageSharp
                        ?.gatsbyImageData
                )}
                title={frontmatter?.Sustainability?.Title}
                text={frontmatter?.Sustainability?.Text}
                parentClasses={{
                    bgColor: 'bg-light',
                }}
            >
                <Button
                    url="/sustainability"
                    text="Find out more"
                    variation="primary"
                />
            </SideBySide>
            <Cta
                text={frontmatter?.CTA2_Text}
                parentClasses={{
                    bgColor: 'bg-primary',
                    title: 'text-white',
                }}
            >
                <Button
                    url="/contact"
                    text={frontmatter?.CTA2_ButtonText}
                    variation="secondary"
                />
            </Cta>
        </>
    )
}

export default IndexPage

export const Head = ({ location, data: { markdownRemark } }) => {
    const { frontmatter } = markdownRemark
    return (
        <Seo
            pathname={location?.pathname}
            title={frontmatter?.metaTitle}
            description={frontmatter?.metaDesc}
            image={getImage(
                frontmatter.metaImg?.childImageSharp?.gatsbyImageData
            )}
        ></Seo>
    )
}

export const pageQuery = graphql`
    {
        markdownRemark(frontmatter: { id: { eq: "index" } }) {
            frontmatter {
                metaTitle
                metaDesc
                metaImg {
                    childImageSharp {
                        gatsbyImageData(width: 1280)
                    }
                }
                Hero {
                    HeroTitle
                    HeroText
                }
                CB_Text
                CTA1_Text
                CTA1_ButtonText
                Freedom {
                    Image {
                        childImageSharp {
                            gatsbyImageData(width: 1280)
                        }
                    }
                    Title
                    Text
                }
                HolidayTents {
                    Image {
                        childImageSharp {
                            gatsbyImageData(width: 1280)
                        }
                    }
                    Title
                    Text
                }
                EventTents {
                    Image {
                        childImageSharp {
                            gatsbyImageData(width: 1280)
                        }
                    }
                    Title
                    Text
                }
                Sustainability {
                    Image {
                        childImageSharp {
                            gatsbyImageData(width: 1280)
                        }
                    }
                    Title
                    Text
                }
                CTA2_Text
                CTA2_ButtonText
            }
        }
    }
`
